<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.setting.transfer')"
        :mask-closable="false"
        :closable="false"
        width="387">
        <Form ref="formValidate" class="formValidate" :model="formValidate" :rules="ruleValidate">
            <p>{{ $t('pages.setting.inputPhoneNumberTip') }}</p>
            <FormItem class="formItem" label="" prop="mobile">
                <Input v-model="formValidate.mobile" :placeholder="this.$t('pages.login.inputPhoneNumber')">
                    <template #prefix>
                        <areas-component :areas="areas" v-model="areaCode"></areas-component>
                    </template>
                </Input>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.nextStep') }}</Button>
        </div>
    </Modal> 
</template>
<script>
    import areasComponent from '@/views/login/components/areasComponent'

    export default {
        name: "",
        mixins: [],
        components: {
            areasComponent
        },
        props: {
            show: {
                default: false
            },
            user_department_id: {
                default: null
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    mobile: ''
                },
                list: [],
                areaCode: this.$store.state.areas.length?this.$store.state.areas[0].area_code:''
            }
        },
        computed: {
            areas() {
                return this.$store.state.areas
            },
            ruleValidate() {
                return {
                    mobile: [
                        { required: true, type: 'string', validator: (rule, value, callback) => {
                            var regex = /^1[3456789]\d{9}$/
                            if (this.areaCode !== '86') {
                                regex = /^\d{1,20}$/
                            }
                            if (!regex.test(value)) {
                                callback(new Error(this.$t('pages.login.inputPhoneNumber')))
                            } else {
                                callback()
                            }
                        }, trigger: 'blur' }
                    ]
                }
            }
        },
        watch: {
            show(val) {
                this.modal = val
            }
        },
        methods: {
            cancel() {
                this.formValidate = {
                    mobile: ''
                }

                this.$refs['formValidate'].resetFields();
                this.$emit('cancel')
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.$Modal.confirm({
                            title: this.$t('common.tip'),
                            content: this.$t('pages.setting.transferTip'),
                            loading: true,
                            onOk: () => {
                                let params = {
                                    user_department_id: this.user_department_id,
                                    mobile: this.formValidate.mobile,
                                    area_code: this.areaCode
                                }
                                this.$api.put('organizations/launch_transfer_action', params).then(() => {
                                    this.$Message.success(this.$t('common.success'))
                                    this.$emit('confirm')
                                    this.cancel()
                                }).finally(() => {
                                    this.$Modal.remove()
                                })
                            }
                        });
                    }
                })
            }
        },
        created() {},
        mounted() {
            this.$api.get('specifications/business/list').then(({ data }) => {
                this.list = data
            })
        }
    }
</script>
<style scoped lang="less">
@deep: ~'>>>';
.formItem @{deep} .ivu-input-prefix {
    width: 60px;
}
.formItem @{deep} .ivu-input-with-prefix {
    padding-left: 60px;
}
</style>