<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.personal.bindPhone')"
        :mask-closable="false"
        :width="387">
        <div v-if="formValidate.token === ''" style="margin-bottom: 10px;">
            <p>{{ $t('pages.personal.unbindedTip') }}</p>
            <p>{{ $t('pages.personal.currentPhone') }}：{{ mobileUnvisible }}</p>
        </div>
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" inline>
            <template v-if="formValidate.token === ''">
                <FormItem prop="message" style="width: 229px;">
                    <Input type="text" size="large" v-model="formValidate.message" :placeholder="$t('pages.login.inputCode')" />
                </FormItem>
                <span id="sms_login_verify" class="message-get" v-if="!sendMsgDisabled" style="width: 115px;" size="large" @click="getMesg('unbind', mobile)">{{ $t('pages.login.getCode') }}</span>
                <Button v-if="sendMsgDisabled" style="width: 115px;" size="large" class="on" disabled>{{ countDownTime }}S</Button>
            </template>
            <template v-else>
                <FormItem class="formItem" label="" prop="mobile2" style="width: 100%;">
                    <Input v-model="formValidate.mobile2" size="large" :placeholder="$t('pages.login.inputPhoneNumber')">
                        <template #prefix>
                            <areas-component :areas="areas" v-model="areaCode"></areas-component>
                        </template>
                    </Input>
                </FormItem>
                <FormItem prop="message2" style="width: 229px;">
                    <Input type="text" size="large" v-model="formValidate.message2" :placeholder="$t('pages.login.inputCode')" />
                </FormItem>
                <span id="sms_login_verify" class="message-get" v-if="!sendMsgDisabled" style="width: 115px;" size="large" @click="getMesg('bind', formValidate.mobile2)">{{ $t('pages.login.getCode') }}</span>
                <Button v-if="sendMsgDisabled" style="width: 115px;" size="large" class="on" disabled>{{ countDownTime }}S</Button>
            </template>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button v-if="formValidate.token === ''" type="primary" :loading="loading" @click="handleUnbind">{{ $t('common.nextStep') }}</Button>
            <Button v-else type="primary" :loading="loading" @click="handleBbind">{{ $t('pages.personal.bind') }}</Button>
        </div>
    </Modal>
</template>
<script>
    import areasComponent from '@/views/login/components/areasComponent'

    export default {
        name: "",
        mixins: [],
        components: {
            areasComponent
        },
        props: {
            show: {
                default: false
            },
            mobile: {
                default: ''
            },
            mobileUnvisible: {
                default: ''
            },
            code: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                formValidate: {
                    mobile2: '',
                    message: '',
                    message2: '',
                    token: '',
                },
                sendMsgDisabled: false,
                countDownTime: 60,
                timer: null,
                areaCode: ''
            }
        },
        computed: {
            areas() {
                return this.$store.state.areas
            },
            ruleValidate() {
                return {
                    mobile2: [
                        { required: true, type: 'string', validator: (rule, value, callback) => {
                            var regex = /^1[3456789]\d{9}$/
                            if (this.areaCode !== '86') {
                                regex = /^\d{1,20}$/
                            }
                            if (!regex.test(value)) {
                                callback(new Error(this.$t('pages.login.inputPhoneNumber')))
                            } else {
                                callback()
                            }
                        }, trigger: 'blur' }
                    ],
                    message: [
                        { required: true, type: 'string', message: this.$t('pages.login.inputCode'), trigger: 'blur' },
                        { type: 'string', pattern: /^\d{4}$/, message: this.$t('pages.login.input4Digits'), trigger: 'blur' }

                    ],
                    message2: [
                        { required: true, type: 'string', message: this.$t('pages.login.inputCode'), trigger: 'blur' },
                        { type: 'string', pattern: /^\d{4}$/, message: this.$t('pages.login.input4Digits'), trigger: 'blur' }

                    ],
                }
            }
        },
        watch: {
            show(val) {
                this.modal = val
                this.areaCode = this.code
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.$emit('cancel')
                this.formValidate = {
                    mobile: '',
                    message: '',
                    message2: '',
                    token: ''
                }
                this.$refs['formValidate'].resetFields();
            },
            handleUnbind() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        let params = {
                            mobile: this.mobile,
                            sms_code: this.formValidate.message,
                            area_code: this.areaCode
                        }
                        this.loading = true
                        this.$api.get('me/account/unbind_mobile_token', { params }).then(({ token }) => {
                            this.formValidate.token = token
                            this.countDownTime = 60
                            this.sendMsgDisabled = false
                            clearInterval(this.timer)
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            },
            handleBbind() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        let params = {
                            mobile: this.formValidate.mobile2,
                            sms_code: this.formValidate.message2,
                            unbind_mobile_token: this.formValidate.token,
                            area_code: this.areaCode
                        }
                        this.loading = true
                        this.$api.put('me/account/mobile', params).then(() => {
                            this.$Message.success(this.$t('pages.personal.chandeSuccess'))
                            this.cancel()
                            this.$emit('confirm')
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$api.put('me/account/mobile', { mobile: this.formValidate.mobile, area_code: this.areaCode }).then(() => {
                            this.$emit('confirm')
                        }).finally(() => {
                            this.loading = false
                        })
                    }
                })
            },
            countDown() {
                if ((this.countDownTime--) <= 0) {
                    this.countDownTime = 60
                    this.sendMsgDisabled = false
                    clearInterval(this.timer)
                }
            },
            getMesg(sms_type, mobile) {
                let params = {
                    sms_type: sms_type,
                    area_code: this.areaCode
                }
                this.$api.get(`auth/captchas/sms/${mobile}`, { params }).then(({ code }) => {
                    if (code === 200) {
                        this.sendMsgDisabled = true
                        clearInterval(this.timer)
                        this.timer = setInterval(this.countDown, 1000)
                    }
                })
            }
        },
        beforeDestroy() {
            clearInterval(this.timer)
        },
        created() {}
    }
</script>
<style scoped lang="less">
@deep: ~'>>>';
.formItem @{deep} .ivu-input-prefix {
    width: 60px;
}
.formItem @{deep} .ivu-input-with-prefix {
    padding-left: 60px;
}
.message-get {
    cursor: pointer;
    display: inline-block;
    width: 115px;
    height: 40px;
    background: #ececec;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
}
</style>