<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('pages.personal.bindWechat')"
        :mask-closable="false"
        :width="387"
        footer-hide
        @on-visible-change="handleVisibleChange">
        <div class="img-wrap">
            <img class="image-center" :src="qrcode_url">
        </div>
        <div class="tip-info">
            {{ $t('pages.personal.scan') }}>{{ $t('pages.personal.follow') }}>{{ $t('pages.personal.bindedSuccess') }}
        </div>
    </Modal>
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            }
        },
        data () {
            return {
                modal: false,
                ticket: '',
                qrcode_url: ''
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.timer = setInterval(this.getQRcode, 1000)
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                clearInterval(this.timer)
                this.ticket = ''
                this.qrcode_url = ''
                this.$emit('cancel')
            },
            getQRcode() {
                let params = {
                    ticket: this.ticket
                }
                this.$api.get('auth/captchas/qrcode/wx_mp', { params }).then(({ qrcode_url, status, ticket }) => {
                    this.qrcode_url = qrcode_url
                    this.ticket = ticket
                    if (status === 'login' || status === 'register') {
                        clearInterval(this.timer)
                        this.$api.put('me/account/wechat', { wxmp_ticket: this.ticket }).then(() => {
                            this.$emit('confirm')
                            this.cancel()
                        })
                    }
                }).catch(() => {
                    clearInterval(this.timer)
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.img-wrap {
    position: relative;
    margin: 0 auto;
    width: 167px;
    height: 167px;
}
.tip-info {
    margin-top: 20px;
    text-align: center;
}
</style>