<template>
    <div id="my-company">
        <h2 v-if="todoList.length !== 0" class="company-title">{{ $t('pages.setting.peddingEnterprise') }}({{ todoList.length }})</h2>
        <ul class="company-wrap">
            <li class="company" v-for="item in todoList" :key="item.user_department_id">
                <div class="company-container">
                    <Avatar size="large" class="avatar" :src="`/cym/${item.organization.profile_uri}`" />
                    <div class="info-block">
                        <h3 class="company-name line-overflow">{{ item.organization.name }}</h3>
                        <p class="company-staff line-overflow">{{ item.organization.user_count }}{{ $t('pages.setting.unit') }}{{ item.organization.user_count>1?$t('pages.setting.employees'):$t('pages.setting.employee') }}</p>
                    </div>
                </div>
                <Dropdown class="more">
                    <a href="javascript:void(0)">
                        <div class="md-more"><Icon class="icon" type="md-more" size="20" /></div>
                    </a>
                    <DropdownMenu slot="list">
                        <template v-if="!item.is_admin_user && item.is_wait">
                            <DropdownItem @click.native="handleAcceptOrReject(item, 'accept')">{{ $t('pages.setting.join') }}</DropdownItem>
                            <DropdownItem @click.native="handleAcceptOrReject(item, 'reject')">{{ $t('pages.setting.reject') }}</DropdownItem>
                        </template>
                        <DropdownItem v-if="item.is_admin_user && item.is_wait" @click.native="handleAcceptOrReject(item, 'accept')">{{ $t('pages.setting.accept') }}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <span class="label" v-if="item.is_admin_user && !item.is_wait">{{ $t('pages.setting.inviteJoining') }}</span>
                <span class="label" v-if="item.is_admin_user && item.is_wait">{{ $t('pages.setting.transfer') }}</span>
            </li>
        </ul>
        <h2 class="company-title">{{ $t('pages.setting.selfCreated') }}({{ createdList.length }})</h2>
        <!-- <Button type="primary" @click="handleCreate('add')">创建企业</Button> -->
        <ul class="company-wrap">
            <li class="company" v-for="item in createdList" :key="item.user_department_id">
                <div class="company-container">
                    <Avatar size="large" class="avatar"  :src="`/cym/${item.organization.profile_uri}`"/>
                    <div class="info-block">
                        <h3 class="company-name line-overflow">{{ item.organization.name }}</h3>
                        <p class="company-staff line-overflow">{{ item.organization.user_count }}{{ $t('pages.setting.unit') }}{{ item.organization.user_count>1?$t('pages.setting.employees'):$t('pages.setting.employee') }}</p>
                    </div>
                </div>
                <Dropdown class="more">
                    <a href="javascript:void(0)">
                        <div class="md-more"><Icon class="icon" type="md-more" size="20" /></div>
                    </a>
                    <DropdownMenu slot="list">
                        <template v-if="!item.is_wait">
                            <DropdownItem @click.native="handleCancel(item.user_department_id)">{{ $t('pages.setting.cancelEnterprise') }}</DropdownItem>
                            <DropdownItem @click.native="handleTransfer(item.user_department_id)">{{ $t('pages.setting.transfer') }}</DropdownItem>
                        </template>
                        <DropdownItem v-else @click.native="handleAcceptOrReject(item, 'cancel')">{{ $t('pages.setting.cancelTransfer') }}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <span class="label" v-if="item.is_wait">{{ $t('pages.setting.transfering') }}</span>
            </li>
        </ul>
        <h2 v-if="joinList.length !== 0" class="company-title">{{ $t('pages.setting.joinedCompanies') }}({{ joinList.length }})</h2>
        <ul class="company-wrap">
            <li class="company" v-for="item in joinList" :key="item.user_department_id">
                <div class="company-container">
                    <Avatar size="large" class="avatar" :src="`/cym/${item.organization.profile_uri}`" />
                    <div class="info-block">
                        <h3 class="company-name line-overflow">{{ item.organization.name }}</h3>
                        <p class="company-staff line-overflow">{{ item.organization.user_count }}{{ $t('pages.setting.unit') }}{{ item.organization.user_count>1?$t('pages.setting.employees'):$t('pages.setting.employee') }}</p>
                    </div>
                </div>
                <Dropdown class="more">
                    <a href="javascript:void(0)">
                        <div class="md-more"><Icon class="icon" type="md-more" size="20" /></div>
                    </a>
                    <DropdownMenu slot="list">
                        <DropdownItem @click.native="handleLeave(item.user_department_id)">{{ $t('pages.setting.leave') }}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </li>
        </ul>
        <create-modal
            :show="createModalShow"
            :type="createType"
            :apply="createdApply"
            @cancel="handleCreateCancel"
            @confirm="handleCreateSubmit"
        ></create-modal>
        <transfer-modal
            :show="transferModalShow"
            :user_department_id="user_department_id"
            @cancel="handleTransferCancel"
            @confirm="handleTransferConfirm"
        ></transfer-modal>
    </div>  
</template>
<script>
    import createModal from './components/create'
    import transferModal from './components/transfer'

    export default {
        name: "",
        mixins: [],
        components: {
            createModal,
            transferModal
        },
        props: [],
        data () {
            return {
                createModalShow: false,
                createType: 'add',
                createdList: [],
                createdApply: {},
                todoList: [],
                joinList: [],
                transferModalShow: false,
                user_department_id: null
            }
        },
        computed: {},
        methods: {
            handleCreate(type, item) {
                this.createModalShow = true
                this.createType = type
                if (type === 'edit') {
                    this.createdApply = item
                }
            },
            handleCreateCancel() {
                this.createModalShow = false
            },
            handleCreateSubmit() {
                this.handleCreateCancel()
                this.getData()
            },
            getData() {
                this.$api.get('organizations/list').then(({ todo_list, admin_list, join_list }) => {
                    this.todoList = todo_list
                    this.createdList = admin_list
                    this.joinList = join_list
                })
            },
            //取消转让
            handleTransfer(rowid) {
                this.transferModalShow = true
                this.user_department_id = rowid
            },
            handleTransferCancel() {
                this.transferModalShow = false
            },
            handleTransferConfirm() {
                this.handleTransferCancel()
                this.getData()
            },
            //注销企业
            handleCancel(rowid) {
                let _this = this
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: this.$t('pages.setting.cancelTip'),
                    onOk: () => {
                        setTimeout(function() {
                            _this.$Modal.confirm({
                                title: this.$t('pages.setting.reminderAgain'),
                                content: this.$t('pages.setting.cancelTip'),
                                loading: true,
                                onOk: () => {
                                    _this.$api.put('organizations/do_leave_action', { user_department_id: rowid, flag: 'leave' }).then(() => {
                                        _this.$Modal.remove();
                                        _this.$Message.success(this.$t('pages.setting.cancelSuccess'))
                                        _this.getData()
                                    })
                                }
                            });
                        }, 500)
                    }
                });
            },
            //接受或拒绝
            handleAcceptOrReject(item, flag) {
                let text = '',
                    params = {
                        user_transfer_id: item.user_transfer_id,
                        flag: flag
                    };
                if (!item.is_admin_user && item.is_wait) {
                    if (flag === 'accept') {
                        text = this.$t('pages.setting.sureJoin')
                    } else if (flag == 'reject') {
                        text = this.$t('pages.setting.sureReject')
                    }
                }
                if (item.is_admin_user && item.is_wait) {
                    if (flag === 'accept') {
                        text = this.$t('pages.setting.acceptTip')
                    } else if (flag === 'cancel') {
                        text = this.$t('pages.setting.sureCancel')
                    }
                }
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: text,
                    loading: true,
                    onOk: () => {
                        this.$api.put('organizations/do_transfer_action', params).then(() => {
                            this.$Modal.remove();
                            this.$Message.success(this.$t('common.success'))
                            this.getData()
                        })
                    }
                });
            },
            //离开
            handleLeave(rowid) {
                this.$Modal.confirm({
                    title: this.$t('common.tip'),
                    content: this.$t('pages.setting.sureLeave'),
                    loading: true,
                    onOk: () => {
                        this.$api.put('organizations/do_leave_action', { user_department_id: rowid }).then(() => {
                            this.$store.dispatch('GET_ORGANIZATION_LIST')
                            this.$Modal.remove();
                            this.$Message.success(this.$t('pages.setting.leaveSuccess'))
                            this.getData()
                        })
                    }
                });
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
<style scoped lang="less">
#my-company {
    padding-top: 60px;
    .company-title {
        display: inline-block;
        margin: 0 20px 40px 0;
        font-size: 18px;
        line-height: 26px;
    }
    .company-wrap {
        width: calc(100% + 40px);
        .company {
            display: inline-block;
            position: relative;
            margin: 0 40px 40px 0;
            width: 350px;
            height: 150px;
            background: #fff;
            box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
            vertical-align: top;
            .company-container {
                position: absolute;
                top: 50%;
                left: 40px;
                transform: translateY(-50%);
                height: 60%;
                width: calc(100% - 40px);
                .avatar {
                    display: inline-block;
                    height: 87px;
                    width: 87px;
                }
                .info-block {
                    position: absolute;
                    left: 138px;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 160px;
                    .company-name {
                        margin-bottom: 11px;
                        width: 100%;
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 29px;
                    }
                    .company-staff {
                        width: 100%;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 29px;
                    }
                }
            }
            .more {
                position: absolute;
                top: 11px;
                right: 10px;
                .md-more {
                    width: 32px;
                    height: 32px;
                    border: 2px solid rgba(0,0,0,0.4);
                    border-radius: 50%;
                    &:hover {
                        border: 2px solid rgba(0,0,0,0.6);
                        .icon {
                            color: rgba(0,0,0,0.6);
                        }
                    }
                    .icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        color: rgba(0,0,0,0.4);
                    }
                }
            }
            .label {
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 0 15px;
                height: 24px;
                background: #1492ff;
                border-radius: 0px 0px 10px 0px;
                color: #f8fbff;
                font-size: 10px;
                line-height: 24px;
            }
        }
    }
}
</style>